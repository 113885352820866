// /*
//  * Tous ce qui concerne la gestion des wishlist (fiche produit et page wishlist)
//  * product_right.php, wishlist_index.php
//  */

var button_add_to_wishlist_initial_click_event;
var button_add_to_wishlist_initial_label;
function restoreWishlistButtonState() {
    $("#addToWishlistButton").html(button_add_to_wishlist_initial_label);
    $("#addToWishlistButton")
        .removeClass("existToWishlistButton")
        .addClass("addToWishlistButton");
    $("#addToWishlistButton")
        .attr("onclick", button_add_to_wishlist_initial_click_event)
        .attr("href", "#");
}


/**
 * Dans la fiche produit. Le bouton ajouter à la wishlist. (product_right.php)
 */
function addToWishlist(evt) {

    var btn_wishlist = $(this);
    var bloc = '#' + $(this).data('bloc');
    var product_id = $(bloc + " #hdn_product_id").val();
    var color_id = $(bloc + " #couleurProd").val();
    var size_id = $(bloc + " #tailleProd").val();
    var need_color_id = ($("#couleurProd").length != 0 && $("#couleurProd").val() != 'none');
    var need_size_id = ($("#tailleProd").length != 0 && $("#tailleProd").val() != 'none');
    var is_model = $(bloc + " #hdn_is_model").val();

    if(parseInt($('#in_basket').val()) === 1){
        var id_select = $(this).attr('class');
        product_id = id_select.split('product_towish basketToWishlist id_');

        color_id = "#couleurProd_"+product_id;
        color_id = color_id.replace(',','');
        color_id = $(color_id).val();
        size_id = "#tailleProd_"+product_id;
        size_id = size_id.replace(',','');
        size_id = $(size_id).val();

        need_color_id = bloc + "#couleurProd_"+product_id;
        need_color_id = need_color_id.replace(',','');
        need_color_id = $(need_color_id).val();
        need_size_id = bloc + "#tailleProd_"+product_id;
        need_size_id = need_size_id.replace(',','');
        need_size_id = $(need_size_id).val();
        need_color_id =  ($(need_color_id).length != 0 && $(need_color_id).val() != 'none');
        need_size_id =  ($(need_size_id).length != 0 && $(need_size_id).val() != 'none');
    }

    // On regarde si le produit est deja dans la wishlist
    if (!checkProductInWishlist(product_id)) {
        //evt.preventDefault();

        if (typeof (button_add_to_wishlist_initial_click_event) == "undefined") {

            button_add_to_wishlist_initial_click_event = $("#addToWishlistButton").attr("onclick");
        }

        if (typeof (button_add_to_wishlist_initial_label) == "undefined") {

            button_add_to_wishlist_initial_label = $("#addToWishlistButton span").html();
        }

        
        var data = {};

        data.product_id = product_id;
        data.size_id = size_id;
        data.color_id = color_id;
        data.need_color_id = need_color_id;
        data.need_size_id = need_size_id;
        data.is_model = is_model;

        if (parseInt($('#in_basket').val()) === 1) {
            data.product_id = data.product_id[1];
        }

        if (document.getElementById('lightbox_achat_express'))
            from_range = true;
        else
            from_range = false;

        $.post(path_relative_root + 'ajax_add_to_wishlist.php?time=' + Date.now(), data, function (resp) {

            $("#addToWishlistButton").parent().removeClass('loading');

            if (resp.error) {

                if (resp.message == "login_required") {

                    var html = createAboxWishlist(resp);

                    if ($('#lightbox_achat_express').length > 0) {
                        $('#lightbox_achat_express').fadeOut("fast", function () {
                            $('#shad').css('display', 'none');
                        });
                    }
                    alert(html);
                } else if (resp.message != "error") {
                    $("#sizeerror").slideDown();
                } else {
                    alert(Translator.translate(resp.result.error_message));
                    return false;
                }
            } else {
                if (parseInt($('#in_basket').val()) === 1) {
                    var del_product = "#del_product_"+product_id;
                    del_product = del_product.replace(',','');

                    $('span', btn_wishlist).html(resp.result.button_label);
                    btn_wishlist.removeClass("addToWishlistButton").addClass("existToWishlistButton");
                    btn_wishlist.removeClass("wishlist").addClass("wishlistFull");
                    btn_wishlist.attr("onclick", "").attr("href", path_relative_root + create_link('wishlist'));

                    addToWishlistUpdateHeader(resp.result.wishlist_size);
                    $(del_product)[0].click();
                } else {
                    $('span', btn_wishlist).html(resp.result.button_label);
                    btn_wishlist.removeClass("addToWishlistButton").addClass("existToWishlistButton");
                    btn_wishlist.removeClass("wishlist").addClass("wishlistFull");
                    btn_wishlist.attr("onclick", "").attr("href", path_relative_root + create_link('wishlist'));

                    wishlist.push(resp.result.wishlist_row_array);
                    addToWishlistUpdateHeader(resp.result.wishlist_size);
                    //evenement DataLayer
                    if (window.dataLayer !== undefined) {
                        window.dataLayer.push({
                            'event': 'addToWishlist',
                        });
                    }
                }

            }
        });

        if (!product_id || typeof product_id == "undefined") {
            return false;
        }

    } else {
        alert(Translator.translate('err_product_exist_in_wishlist'));
        $('#abox .form_submit').hide(); 
    }
}

function createAboxWishlist(resp) {
    var html =
        '<div class="box_wishlist">' +
        resp.result.error_message +
        '<div class="wrapper_btn form_submit">' +
        '<a class="button primary dark" href="#" onclick="wishlistRedirectLogin();">' +
        resp.result.btn_connexion +
        "</a>" +
        "</div>" +
        "</div>";

    return html;
}

// /*
//  * Permet de changer l'action et le visuel du bouton d'ajout à la wishlist
//  * Aurelien Renault
//  */
/**
 *
 * @param productId
 * @returns {boolean}
 */
function checkProductInWishlist(productId) {

    var isProductPage = $("#produit_id").val();
    var colorId = $("#couleurProd_" + productId).val();
    var sizeId = $("#tailleProd_" + productId).val();
    if (isProductPage > 0) {
        colorId = $("#couleurProd").val();
        sizeId = $("#tailleProd").val();
    }

    if (typeof productId != "undefined" && typeof colorId != "undefined" && typeof sizeId != "undefined") {
        $.ajax({
            type: 'get',
            url: path_relative_root + create_link('get_user_wishlist'),
            data:{'product_id': productId, 'size_id': sizeId, 'color_id': colorId},
            success: function (response) {
                var userWishlist = JSON.parse(response);
                var wishlistButton = $("#addToWishlistButton_" + productId);
                if (isProductPage > 0) {
                    wishlistButton = $("#addToWishlistButton");
                }
                if (typeof userWishlist !== undefined && userWishlist.length > 0) {
                    wishlistButton.attr("onclick", "");
                    wishlistButton.attr(
                        "href",
                        path_relative_root + create_link("wishlist")
                    );
                    $("#addToWishlistButton span").html(Translator.translate('exist_to_wishlist_button_label'));
                    wishlistButton
                        .removeClass("addToWishlistButton")
                        .addClass("existToWishlistButton")
                        .removeClass("wishlist")
                        .addClass("wishlistFull");
                } else {
                    wishlistButton.attr(
                        "onclick",
                        "addToWishlist.call(this, 1);return false;"
                    );
                    $("#addToWishlistButton span").html(Translator.translate('add_wishlist'));
                    wishlistButton.attr("href", "javascript:;");
                    if (wishlistButton.hasClass('wishlistFull')) {
                        wishlistButton
                            .removeClass("wishlistFull")
                            .addClass("wishlist");
                    }
                }
            }
        });
    }
}

function wishlistRedirectLogin() {
    $.get(
        path_relative_root + create_link("ajax_add_to_wishlist")+"?login_required=1",
        function() {
            document.location.href =
                path_relative_root +
                create_link("connexion_login") +
                "?from=front";
        }
    );
}

function addToWishlistUpdateHeader(nr) {
    $(".btn_top_wishlist .gris").html(nr);

    if (nr == 0) {
        $("#basket1").show();
        $("#basket2").hide();
        $(".btn_top_wishlist").hide();
        $("#number_wishlist_top").html("(0)");
    } else {
        $("#basket2").show();
        $("#basket1").hide();
        $(".btn_top_wishlist").show();
        $("#number_wishlist_top").html("(" + nr + ")");
    }

    if($('.wishlist .wrapper_menu_compte').length) {
        $('#number_wishlist_top', '.wishlist .wrapper_menu_compte').html("(" + nr + ")");
    }

    var response_basket = ajax_file(path_relative_root + create_link('ajax_show_wishlist'));
    $("#wishlist_top").html(response_basket);
}

function scanForChanges() {
    var totalPrice = 0;
    totalPoints = 0;
    selectedProducts = new Array();
    $(".checkbox_select_product").each(function() {
        var element = $(this);
        var id = element.attr("data-id");

        /**
         * Sélectionne le bloc .product_ctn
         */
        var productCnt = $(this).closest('.product_ctn');

        if (element.is(":checked")) {
            var price = parseFloat(element.attr("data-price"));

            if (price) {
                totalPrice += price;
            }

            selectedProducts.push(id);
            if (element.attr("pointscadeau")) {
                totalPoints += parseInt(element.attr("pointscadeau"));
            }

            /**
             * Ajoutez la classe
             * 'checked'
             */
            productCnt.addClass('checked');
        } else {
            /**
             * Retirez la classe
             * 'checked'
             */
            if(productCnt.hasClass('checked')) {
                productCnt.removeClass('checked');
            }
        }
    });
    var txt = "",
        text = "";
    var priceSpan = $("#total_price");
    switch (selectedProducts.length) {
        case 0:
            txt = $texts[0] + ' :';
            if (lang_iso == 'en') {
                priceSpan.html('<span id="total_price">0<span class="devise">&euro;</span><sup></sup><span class="decimal_price"></span></span>');
            } else {
                priceSpan.html('<span id="total_price">0<span class="devise">&euro;</span><sup></sup><span class="decimal_price"></span></span>');
            }

            break;
        case 1:
            text = $texts[1];
        default:
            if (text == '')
                text = $texts[2];

            pricefloor = Math.floor(totalPrice);

            if (pricefloor == totalPrice) {
                decimal = '';
            } else {
                decimal = Math.round((totalPrice - pricefloor) * 100);
                decimal = '' + decimal;
                if (decimal.length == 1) {
                    decimal = decimal + '0';
                }
            }
            if (lang_iso == 'en') {
                priceSpan.html('<span id="total_price">' + pricefloor + '<span class="devise">&euro;</span><sup></sup><span class="decimal_price">' + decimal + '</span></span>');
            } else {
                priceSpan.html('<span id="total_price">' + pricefloor + '<span class="devise">&euro;</span><sup></sup><span class="decimal_price">' + decimal + '</span></span>');
            }

            txt = '<span class="wishlist_count">' + selectedProducts.length + '</span> ' + text + ' :';
    }

    $(".txt", "#nrArticlesSelected").html(txt);
}
// function addProductToCart(wpId, pId, sId, cId) {
//     $.post(
//         path_relative_root + create_link("ajax_wishlist_add_product_to_cart"),
//         {
//             product_id: pId,
//             size_id: sId,
//             color_id: cId,
//         },
//         function(resp) {
//             if (resp == "ok") {
//                 document.location.href =
//                     path_relative_root + create_link("order_basket");
//             } else {
//                 if ($("#ckwp_" + wpId).attr("pointscadeau")) {
//                     alert(Translator.translate("err_no_points"));
//                 }
//             }
//         }
//     );
// }

// /**
//  * Ferme une popup par rapport à son nom et ferme aussi le shad
//  * Utilisation : #sendwishlistbox qui est généré dinamiquement dans wishlist_index.php
//  */
// function closePopup(popup_id, shad_id) {
//     if (shad_id == undefined) {
//         shad_id = "shad";
//     }
//     var $popup = $("#" + popup_id),
//         $shad = $("#" + shad_id);

//     $shad.unbind("click.popup");
//     $popup.slideUp("slow", function() {
//         $shad.fadeOut("slow", function() {
//             if ($("#wrapper_sendwishlist").hasClass("sended")) {
//                 $("#sendwishlistbox").remove();
//             }
//         });
//     });
// }

// /**
//  * Affiche la lighbox de la wishlist, dynamiquement si la box n'existe pas
//  * Utilisation : wishlist_index.php
//  */
function showsendwishlistmodal() {

    if ($('#sendwishlistbox').length == 0) {
        $.ajax({
            url: path_relative_root + create_link('ajax_send_wishlist') + '?liste_produit=' + selectedProducts,
            success: function (response) {
                if ($('#sendwishlistbox').length == 0) {
                    $('body').append(response);
                    if ($('#g-recaptcha').length > 0) {

                        grecaptcha.render('g-recaptcha');
                    }
                }
            },
            complete: function () {
                openMultiShad('sendwishlistbox');
            }
        });
    } else {
        openMultiShad('sendwishlistbox');
    }
}

 /**
  * Envoie le formulaire de la lightbox wishlistavec gestion des erreurs
  * Utilisation : wishlist_index.php
  */
 function sendWishListForm() {
     $("#sendWishListSubmit").css({display: "none"});
     $("#wrapper_loader_send").css({display: "block"});
     var content_id = $("#send_wishlist_ajax");
     var content;
     if (
         $("#sendWishListToMails").length &&
         $("#sendWishListToMessage").length &&
         $("#sendWishListYourName").length &&
         $("#sendWishListYourMail").length
     ) {
         var to_mails = $("#sendWishListToMails").val();
         var to_message = $("#sendWishListToMessage").val();
         var your_name = $("#sendWishListYourName").val();
         var your_mail = $("#sendWishListYourMail").val();
         var token_security = $("#token_security_wishlist").val();
         var captcha = $("#g-recaptcha-response").val();

         $.ajax({
           url:
             path_relative_root +
             create_link("ajax_send_wishlist_form") +
             "?to_mails=" +
             to_mails +
             "&to_message=" +
             to_message +
             "&your_name=" +
             your_name +
             "&your_mail=" +
             your_mail +
             "&token_security=" +
             token_security +
             "&g-recaptcha-response=" +
             captcha
         }).done(function(response) {
           $(".zone_text").css({ border: "" });

           if (response.split(",")[0] == "ok") {
             var s_adresse = response.substr(3);

             var sing =
               response.split(",").length > 2
                 ? "sendfriend_ok_plur"
                 : "sendfriend_ok_sing";

             content = "<div>" + Translator.translate(sing) + "</div>";
             content += '<p style="margin-top: 10px;margin-bottom: 30px;padding:0;text-align:center;">' + s_adresse + "</p>";
           }
           if (response == "1" || response == "2") {
             content = '<p class="txt_err_sendfriend">' + Translator.translate('sendfriend_error_mail_friends_invalid') + "</p>";
             $("#sendWishListToMails").addClass("inputErr");
           } else {
             $("#sendWishListToMails").removeClass("inputErr");
           }
           if (response == "3") {
             content = '<p class="txt_err_sendfriend">' + Translator.translate('sendfriend_error_no_message') + "</p>";
             $("#sendWishListToMessage").addClass("inputErr");
           } else {
             $("#sendWishListToMessage").removeClass("inputErr");
           }
           if (response == "4") {
             content = '<p class="txt_err_sendfriend">' + Translator.translate('sendfriend_error_no_name') + "</p>";
             $("#sendWishListYourName").addClass("inputErr");
           } else {
             $("#sendWishListYourName").removeClass("inputErr");
           }
           if (response == "5" || response == "6") {
             $("#sendWishListYourMail").addClass("inputErr");

             if (response == "5") {
               content =
                 '<p class="txt_err_sendfriend">' + Translator.translate('sendfriend_error_no_my_mail') + "</p>";
             } else {
               content = '<p class="txt_err_sendfriend">' + Translator.translate('sendfriend_error_my_mail_invalid') + "</p>";
             }
           } else {
             $("#sendWishListYourMail").removeClass("inputErr");
           }

           if (response == "token_error") {
             content = '<p class="txt_err_sendfriend">' + Translator.translate('error_token_security') + "</p>";
           }

           if (response.split(",")[0] == "7") {
             var s_adresse = response.substr(3);
             var sing =
               response.split(",").length > 2
                 ? "sendfriend_no_ok_plur"
                 : "sendfriend_no_ok_sing";
             content = "<div>" + Translator.translate(sing) + "</div>";
           }
           if (
             response.split(",")[0] == "7" ||
             response.split(",")[0] == "token_error" ||
             response.split(",")[0] == "ok"
           ) {
             content_id = $("#sendfriend_alert_mail");

             $("#sendfriend_alert_mail").css({
               display: "block",
               opacity: 0
             });
             $("#wrapper_sendwishlist")
               .addClass("sended")
               .css({ display: "block", opacity: 1 })
               .animate({ opacity: 0 }, function() {
                 $("#wrapper_sendwishlist").css({ display: "none" });
                 $("#sendfriend_alert_mail").animate(
                   { opacity: 1 },
                   function() {
                     $("#sendfriend_alert_mail").css({
                       display: "block"
                     });
                   }
                 );
               });
           } else {
             $("#sendfriend_form_buttons").removeClass("loading");
           }

           content_id.html(content).css({ display: "block" });
         });
     }

     return false;
 }

// /**
//  * Utiliser pour ajouter un magasin dans ses favoris (boutique_details)
//  */
// function addToMyFavorites() {
//     var is_favorite_elem = document.getElementById("is_favorite");

//     var self = $(this);
//     var magasin_id = self.attr("data-magasinid");
//     var client_id = self.attr("data-clientid");
//     var action = self.attr("data-action");

//     // Si l'action est de détruire alors on est dans favorites_stores et on souhaite supprimer le bloc en entier
//     if (action == "destroy") {
//         var act = "del";
//     } else {
//         // On est dans store_details et on doit recupérer le statut actuel du magasin pour savoir si on doit le supprimer ou l'ajouter
//         if (is_favorite_elem.value == "1") {
//             var act = "del";
//         } else {
//             var act = "add";
//         }
//     }

//     $.ajax({
//         url: path_relative_root + "ajax_manage_favorites_stores.php",
//         type: "post",
//         dataType: "html",
//         data: "act=" + act + "&magasin_id=" + magasin_id,
//         success: function(response) {
//             if (response) {
//                 // Destruction du bloc
//                 if (action == "destroy") {
//                     self.slideUp("slow", function() {
//                         self.remove();
//                     });
//                 } else {
//                     if (act == "add") {
//                         var libelle =
//                             "<span>" +
//                             Translator.translate("delete_to_favorites") +
//                             "</span>";

//                         is_favorite_elem.value = 1;
//                     } else {
//                         var libelle =
//                             "<span>" +
//                             Translator.translate("add_to_favorites") +
//                             "</span>";

//                         is_favorite_elem.value = 0;
//                     }
//                     self.html(libelle);
//                 }
//             }
//         },
//     });
// }

// //Function to load previous selected Size product in basket
function loadSelectedSize(cnt_sizes) {

    var sizes = document.getElementsByName('itm_size');

    $.ajax({
        type: 'post',
        url: path_relative_root + create_link('ajax_size_selected'),
        success: function (response) {
            if (response && response.length > 0) {
                response = $.parseJSON(response);
                var selected_size = (response.selected_size);
                var current_size;
                var current_element;
                var sizeProd = document.getElementById("tailleProd");
                var html = '';

                for (var i = 0; i < sizes.length; i++) {
                    current_element = sizes.item(i).value;
                    if ((selected_size === current_element && current_element !== null) && (!sizes.item(i).classList.contains("disabled"))) {
                        sizes.item(i).checked = true;
                        sizeProd.value = current_element;
                        enableAddCart();
                    }
                }
            }
        }
    });
}

function addWishListNoConnect(prod_id) {
    $.post(
        path_relative_root + create_link("ajax_add_to_wishlist") + "?time=" + Date.now(),

        function (resp) {
            var $alertboxLogin = $("#abox");
            var $alertboxBtns = $("#abox .wrap_btn_abox")

            // var html =
            //     '<div class="light_title"></div>';

            if (resp.error) {
                if ($alertboxLogin.length == 0) {
                    $alertboxLogin = $(
                        // '<div id="abox">' + html + "</div>"
                        '<div id="abox"></div>'
                    );
                }

                if (resp.message == "login_required") {
                    if ($('#abox .btn_container.btn_cnx').length == 0) {
                        
                        var html = createAboxWishlist(resp);


                        $alertboxLogin
                            .find(".txt_alert")
                            .html(
                                html
                            );

                    }


                }

                openMultiShad('abox');

            } else {
                $("span", btn).html(resp.result.button_label);
                btn.removeClass("addToWishlistButton").addClass(
                    "existToWishlistButton"
                );
                btn.attr("onclick", "");
                if (typeof isWeddingSpace == "undefined") {
                    btn.attr("href", resp.result.button_link);
                }

                wishlist.push(resp.result.wishlist_row_array);
                addToWishlistUpdateHeader(resp.result.wishlist_size);
            }
        }
    )
}

function addWishNoConnect(div_id, user, reference, is_alliance, code_couleur, idprod, wishlist) {
    if (user) {
        ajax_achat_express(reference, is_alliance, code_couleur, idprod, wishlist);
    } else {
        addWishListNoConnect(div_id);
    }
}

function addToWishlistRay(user) {

    // On utilise le même que dans la fiche produit car cela fait la même chose
    // mais on donne un nom différent au cas où
    if (user) {
        var btn = $(this);
        var productId = btn.data('productid');
        var productRef = btn.data('product-ref');
        ajax_achat_express(productRef, true, productId, true);
    } else {
        addToWishlist.call(this);
    }

}